.sectionProjets {

    .imageOhmyfood {
        border-radius: 0vh 170vh 170vh 0vh;
    }

    .introFood {
        width: 91vh;
        height: 53%;
        background-color: #9b724c;
        background-image: url("https://www.transparenttextures.com/patterns/wood-pattern.png");
        padding: 10vh;
        border-radius: 8vh 8vh 8vh 1vh;

        p {
            background: beige;
            padding: 5vh;
            border-radius: 4vh 4vh 4vh 1vh;
            font-style: italic;
            font-weight: 900;
            font-size: 2.7vh;
        }
    }

    .articleMessagePaul {

        .MaquettesOhmyfood {
            height: 76vh;
            border-radius: 50px;
            border: 2vh solid rgb(112, 22, 150);
            cursor: zoom-in;
        }

        .img-equipe {
            float: left;
        }

        .messagePaul {
            display: flex;
            height: 43vh;
            margin: 0 5vh;


            .messagePaul-texte {
                width: 51vh;
                margin: 0 -3vh;
                border-radius: 2vh;
                font-weight: 600;
                line-height: 1.2;
                font-size: 2.6vh;
                overflow: hidden;
                padding: 3vh;
                transform: rotate(-11deg);
                background-color: rgba(255, 255, 255, 0.65);
                -webkit-backdrop-filter: blur(3px);
                backdrop-filter: blur(3px);
                border: rgba(255, 255, 255, 0.0784313725) 0.1px solid;

                background: #ffffffb5;
                // border: 5px solid rgb(174, 162, 146);


                img {
                    width: 6vh;
                    margin: 0vh 1vh;
                    shape-outside: circle(5.11vh at 3.78vh 4.11vh);
                }
            }

            .Paul-texte2 {
                transform: rotate(9deg);
            }

            .contenu1 {
                border-radius: 100% 0% 0% 100% / 53% 47% 53% 47%;
            }

            .contenu2 {
                border-radius: 0% 100% 100% 0% / 47% 53% 47% 53%;
            }

            .messagePaul-contenu {
                border: 2vh solid black;
                width: 18vh;
                height: unset;
                background: black;
                overflow: hidden;
                // box-shadow: -1px -1px 32px -3px rgba(0, 0, 0, 0.37);
                // -webkit-box-shadow: -1px -1px 32px -3px rgba(0, 0, 0, 0.37);
                // -moz-box-shadow: -1px -1px 32px -3px rgba(0, 0, 0, 0.37);
                // border: 5px solid rgb(174, 162, 146);

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .Message--contenu-B {
                border-radius: 30vh;
            }

            .Message-B {
                margin-top: 2vh;
                margin-bottom: 0vh;
                font-size: 1.9vh;
                padding: 1vh 4vh;

                img {
                    width: 5vh;
                }
            }
        }
    }



    .articleMessagePaul--2 {
        position: relative;
        width: unset;
        margin: 0 14vh;

        .articleMessagePaul2--textes {
            position: absolute;
            top: 0vh;
            left: unset;
            z-index: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 1.9vh;

            img {
                width: 9vh;
                height: 9vh;

            }

            .messagePaul2_textes-divs {
                border-radius: 1vh 8vh 8vh 8vh;
                -webkit-backdrop-filter: blur(3px);
                backdrop-filter: blur(6px);
                background-color: rgba(255, 255, 255, 0.74);
                border: 1px solid #FFF;
                color: #015feb;
                padding: 0.6vh;
                width: 89vh;

                p {
                    display: flex;
                }
            }
        }

        .messagePaul--2 {
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: unset;
            margin: 2vh;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            .photo-ronde {
                border-radius: 50% 50% 4% 50%;
                background: rgb(132, 132, 132);
                width: 30vh;
                height: 30vh;
                overflow: hidden;
                margin-right: 1vh;
                border: 2vh solid rgb(5, 91, 223);
            }

            .photo-rectangle {
                height: 40vh;
                background: #7c7c7c;
                border-radius: 4% 40%;
                width: 40vh;
                margin: 2vh -4vh 0 0;
                overflow: hidden;
                border: 2vh solid rgb(183, 171, 153);
            }

            .photo-ronde--2 {
                border-radius: 4% 50% 50% 50%;
            }

            .photo-rectangle--2 {
                margin: 0 0 2vh -6vh;
            }
        }
    }



    .VideoOhmyfood {
        border-radius: 65% 35% 90% 10% / 39% 71% 29% 61% !important;
        border: 2vh solid rgb(112, 22, 150) !important;

        video {
            height: 41vh;
            overflow: hidden;
            border-radius: 4vh;
            background: black;
            box-shadow: 0px 0px 40px 0px rgba(0, 0, 0);
            -webkit-box-shadow: 0px 0px 40px 0px rgb(0, 0, 0);
            -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0);
        }
    }

    .articleSiteOhmyfood {
        border: 2vh solid rgb(112, 22, 150) !important;
    }
}