/* Première police */
@font-face {
    font-family: 'Ambidexter';
    /* Nom de la police */
    src: url('../assets/Ambidexter/ambidexter_regular.woff2') format('woff2'),
        /* Format de la police */
        url('../assets/Ambidexter/ambidexter_regular.woff') format('woff');
}

/* Deuxième police */
@font-face {
    font-family: 'Aileron-Light';
    /* Nom de la police */
    src: url('../assets/aileron_0102/Aileron-Light.otf') format('opentype'),
        /* Format de la police */
}

/* Troisième police */
@font-face {
    font-family: 'Aileron-Regular';
    /* Nom de la police */
    src: url('../assets/aileron_0102/Aileron-Regular.otf') format('opentype'),
        /* Format de la police */

}


body {
    width: 100vw;
    height: 100vh;
    margin: 0;
    font-Family: 'Aileron-Regular';
    color: #FFF;
    background: black;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}


ul {
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    padding: 0 2vh;
}

a {
    text-decoration: none;
}



/* Grain -------------------------------------------------------------------------------------------------- */
/* Conteneur pour l'effet de grain */
#root {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

/* Grain */
#root::after {
    content: "";
    position: absolute;
    width: 200vw;
    height: 200vh;
    left: -50%;
    top: -50%;
    background: #ffffff00 url("../assets/noise.png") repeat 0 0;
    animation: grain 1.5s steps(6) infinite, 1000ms infinite noise;
    // filter: contrast(100%) brightness(150%);
    z-index: 999;
    pointer-events: none;
}

main,
.sectionProjets,
#image-track {
    display: flex;
    align-items: center;
    height: 100%;
}

.grain-img {
    z-index: -1;
}

@keyframes noise {

    0%,
    100% {
        background-position: 0 0;
    }

    10% {
        background-position: -5% -10%;
    }

    20% {
        background-position: -15% 5%;
    }

    30% {
        background-position: 7% -25%;
    }

    40% {
        background-position: 20% 25%;
    }

    50% {
        background-position: -25% 10%;
    }

    60% {
        background-position: 15% 5%;
    }

    70% {
        background-position: 0 15%;
    }

    80% {
        background-position: 25% 35%;
    }

    90% {
        background-position: -10% 10%;
    }
}

@keyframes grain {

    0%,
    100% {
        transform: translate(0, 0);
    }

    10% {
        transform: translate(-5%, -10%);
    }

    30% {
        transform: translate(3%, -15%);
    }

    50% {
        transform: translate(12%, 9%);
    }

    70% {
        transform: translate(9%, 4%);
    }

    90% {
        transform: translate(-1%, 7%);
    }
}