.sectionProjetsSophieBluel {
    background-color: #e2e1dd !important;
}

.sectionProjets {

    #gallery {
        height: 100%;
        width: 140vmax;
        position: absolute;
    }

    .tile {
        border-radius: 1vmax;
        position: absolute;
        transition: transform 800ms ease;
        height: 16%;
        width: 32%;
    }

    .tile:hover {
        transform: scale(1.1);
    }

    .tile:hover>img {
        opacity: 1;
        transform: scale(1.01);
    }

    .tile>img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: inherit;
        opacity: 0.5;
        transition: opacity 800ms ease,
            transform 800ms ease;
        background-color: #e2e1dd !important;
    }

    .tile:nth-child(1) {

        height: 14%;
        width: 20%;
        left: 5%;
        top: 5%;
    }

    .tile:nth-child(2) {

        height: 24%;
        width: 14%;
        left: 42%;
        top: 12%;
    }

    .tile:nth-child(3) {

        height: 18%;
        width: 16%;
        left: 12%;
        top: 34%;
    }

    .tile:nth-child(4) {

        height: 14%;
        width: 12%;
        left: 45%;
        top: 48%;
    }

    .tile:nth-child(5) {

        height: 16%;
        width: 32%;
        left: 8%;
        top: 70%;
    }

    .tile:nth-child(6) {

        height: 24%;
        width: 24%;
        left: 68%;
        top: 8%;
    }

    .tile:nth-child(7) {

        height: 16%;
        width: 20%;
        left: 50%;
        top: 74%;
    }

    .tile:nth-child(8) {

        height: 24%;
        width: 18%;
        left: 72%;
        top: 42%;
    }

    .tile:nth-child(9) {

        height: 10%;
        width: 8%;
        left: 84%;
        top: 84%;
    }

    .tile:nth-child(10) {

        height: 10%;
        width: 8%;
        left: 98%;
        top: 84%;
    }

    .sophieTitre {
        box-shadow: 0px 0px 23px 24px #e2e1ddd9;
        background: #e2e1ddd9;
        height: 21vh;
        margin: 10vh 30vh;
        border-radius: 9.5vh;
        font-size: 11vh;
        width: 138vh;
        font-family: Ambidexter;
        color: #000000d9;
        text-align: center;
        padding: 9vh;
    }

    .sophieIntro {
        box-shadow: 0px 0px 23px 24px rgba(226, 225, 221, 0.8509803922);
        background: rgba(226, 225, 221, 0.8509803922);
        height: 27vh;
        margin: 10vh 21vh;
        border-radius: 9.5vh;
        color: rgba(0, 0, 0, 0.8509803922);
        text-align: center;
        padding: 5vh;
        border: 1px solid rgba(0, 0, 0, 0.4392156863);
        width: 100vh;
        font-size: 4vh;

    }

    .messageCharlotte {
        color: rgba(0, 0, 0, 0.75);

        img {
            position: absolute;
            top: 0vh;
            left: unset;
            z-index: 0;
            height: 100%;
            filter: grayscale(80%);
            box-shadow: 15px 14px 2em rgba(226, 225, 221, 0.8509803922), -1em 0 3em rgba(226, 225, 221, 0.8509803922);
            width: 87vh;
            object-fit: cover;
            object-position: 50%;
            cursor: zoom-in;
        }

        .messageParagraphre-1 {
            background-color: #f8f7f3 !important;
            border: 1px solid #D4D3C9;
            padding: 6vh;
            width: 73vh;
            height: 26vh;
            border-radius: 0;
            font-size: 3vh;

        }

        .messageParagraphre-2 {
            position: relative;
            height: 80%;
            margin: 0 0 0 26vh;

            p {
                padding: 2vh;
                background-color: rgba(233, 232, 227, 0.7);
                border: 1px solid #D4D3C9;
                backdrop-filter: blur(3px);
                border-radius: 2.5vh;
                z-index: 1;
                position: relative;
                font-size: 2.5vh;
                width: 38vh;
                margin: 10vh -17vh;

                &:nth-child(2) {
                    margin: 10vh -7vh;
                }

                &:nth-child(3) {
                    margin: 10vh 7vh;
                }
            }

        }


        .messageParagraphre-3 {
            position: relative;
            height: 80%;
            margin: 0 50vh;
            width: 50vh;

            p {
                padding: 2vh;
                background-color: rgba(233, 232, 227, 0.7);
                border: 1px solid #D4D3C9;
                backdrop-filter: blur(3px);
                border-radius: 2.5vh;
                z-index: 1;
                position: relative;
                font-size: 2.5vh;
                width: 38vh;
                margin: 10vh -16vh;
            }

        }
    }

    .mission {
        box-shadow: 0px 0px 23px 24px rgba(226, 225, 221, 0.8509803922);
        background: rgba(226, 225, 221, 0.8509803922);
        height: 70%;
        width: 130vh;
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.4392156863);
        border-radius: 9.5vh;
        overflow: hidden;
        font-size: 3vh;

        h2 {
            font-family: Aileron-regular;
            color: #000000d9;
            font-style: italic;

        }

        img {
            height: 100%;
            filter: grayscale(100%);
            box-shadow: 15px 14px 2em #0000001c, -1em 0 3em #00000061;
            width: 68vh;
            object-fit: cover;
            object-position: 50%;
        }

        div {
            height: 100%;
            margin: 10vh;
        }
    }


    .VideoSophie {
        box-shadow: 0px 0px 23px 24px rgba(226, 225, 221, 0.8509803922);
        background: rgba(226, 225, 221, 0.8509803922) !important;
        border: 0 !important;
        border-radius: 0 !important;

        video {
            border-radius: 0 !important;

        }
    }

    .articleSiteSophie {
        border: 2vh solid rgba(226, 225, 221, 0.8509803922) !important;
    }
}