.sectionProjets {
    background-color: #e9e8e3;
    color: black;
    overflow-y: hidden;
    cursor: ew-resize;
    scrollbar-color: #0006 #1717171a;
    scrollbar-width: thin;
    scroll-behavior: smooth;


    #image-track {
        user-select: none;
        /* -- Prevent image highlighting -- */
        justify-content: unset;

        .couverture {
            height: 100vh;
            object-fit: cover;
            object-position: 100% center;
        }

    }

    article {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Aileron-Light;
        height: 100%;
        margin: 0 10vh;
        z-index: 1;
        font-size: 3vh;
    }

    .articleVideoPresentation {
        width: 100vh;
        height: 76%;
        overflow: hidden;
        flex-direction: column;
        border-radius: 20vh 20vh 0vh 0vh;
        background-color: rgba(255, 255, 255, 0.71);
        align-items: center;
        border: 2vh solid rgb(183, 171, 153);

        h1,
        h2 {
            font-family: Aileron-regular;
            margin: 0vh 0 8vh 0vh;
            color: #000000d9;
            font-size: 2.9vh;
            font-style: italic;
            font-weight: 900;
        }

        video {
            height: 41vh;
            overflow: hidden;
            border-radius: 4vh;
            background: black;
            box-shadow: 0px 0px 40px 0px rgba(0, 0, 0);
            -webkit-box-shadow: 0px 0px 40px 0px rgb(0, 0, 0);
            -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0);
            cursor: pointer;

        }
    }

    .articleSite {
        width: 177vh;
        height: 76vh;
        border-radius: 3vh;
        overflow: hidden;
        position: relative;
        border: 2vh solid rgb(5, 91, 223);

        img {
            height: 100%;
            object-fit: cover;
            object-position: 100% top;
            width: 100%;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1;
        }

        h2 {
            position: absolute;
            z-index: 2;
            color: white;
            text-align: center;
            font-family: Ambidexter;
            font-size: 11vh;
            font-weight: 100;
            margin-bottom: 0vh;
            text-shadow: 0 0 15px #000;
            cursor: pointer;
        }
    }


}