.sectionProjets {

    #image-track {

        .imagebooki {
            border-top-right-radius: 50vh;
            border: 11px solid rgb(202, 188, 167);
        }
    }

    .articleIntro {
        width: 72vh;

        .img-intro {
            width: 17vh;
            shape-outside: circle(8.57vh at 9.13vh 11.2vh);
        }
    }

    .articleMessageSarah {
        align-items: flex-start;
        width: 100vh;
        height: 76%;
        font-size: 2vh;

        .img-equipe {
            float: left;
        }

        .messageSarah {
            width: 50%;
            margin: 1vh;
            height: 76vh;

            .messageSarah-texte {
                background: #ffffffb5;
                // border: 5px solid rgb(174, 162, 146);
                padding: 1vh 1.5vh;
                margin: 2vh 0;
                margin-top: 0;
                border-radius: 8vh 8vh 8vh 1vh;
                color: #0360ed;

                img {
                    width: 6vh;
                    margin: 0vh 1vh;
                    shape-outside: circle(5.11vh at 3.78vh 4.11vh);
                }
            }

            .messageSarah-contenu {
                border-top-left-radius: 50%;
                border-top-right-radius: 50%;
                overflow: hidden;
                height: 50vh;
                border: 2vh solid rgb(190, 177, 158);
                // box-shadow: -1px -1px 32px -3px rgba(0, 0, 0, 0.37);
                // -webkit-box-shadow: -1px -1px 32px -3px rgba(0, 0, 0, 0.37);
                // -moz-box-shadow: -1px -1px 32px -3px rgba(0, 0, 0, 0.37);
                // border: 5px solid rgb(174, 162, 146);

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    cursor: zoom-in;
                }
            }

            .Message--contenu-B {
                border-radius: 30vh;
            }

            .Message-B {
                margin-top: 2vh;
                margin-bottom: 0vh;
                font-size: 1.9vh;
                padding: 1vh 4vh;

                img {
                    width: 5vh;
                }
            }
        }
    }



    .articleMessageSarah--2 {
        position: relative;
        width: unset;
        margin: 0 14vh;

        .articleMessageSarah2--textes {
            position: absolute;
            top: 0vh;
            left: unset;
            z-index: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 1.9vh;

            img {
                width: 9vh;
                height: 9vh;

            }

            .messageSarah2_textes-divs {
                border-radius: 1vh 8vh 8vh 8vh;
                -webkit-backdrop-filter: blur(3px);
                backdrop-filter: blur(6px);
                background-color: rgba(255, 255, 255, 0.74);
                border: 1px solid #FFF;
                color: #015feb;
                padding: 0.6vh;
                width: 89vh;

                p {
                    display: flex;
                }
            }
        }

        .messageSarah--2 {
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: unset;
            margin: 2vh;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            .photo-ronde {
                border-radius: 50% 50% 4% 50%;
                background: rgb(132, 132, 132);
                width: 30vh;
                height: 30vh;
                overflow: hidden;
                margin-right: 1vh;
                border: 2vh solid rgb(5, 91, 223);
            }

            .photo-rectangle {
                height: 40vh;
                background: #7c7c7c;
                border-radius: 4% 40%;
                width: 40vh;
                margin: 2vh -4vh 0 0;
                overflow: hidden;
                border: 2vh solid rgb(183, 171, 153);
            }

            .photo-ronde--2 {
                border-radius: 4% 50% 50% 50%;
            }

            .photo-rectangle--2 {
                margin: 0 0 2vh -6vh;
            }
        }
    }


}