.footer {
    position: absolute;
    inset: auto auto 0%;
    padding: 1vh;
    border-radius: 0.5rem;
    background-color: rgba(0, 0, 0, 0.41);
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(61px);
    color: rgba(255, 255, 255, 0.77);
    margin: 5vh;
    font-size: 2vh;
    font-family: Aileron-Light;

    p {
        margin: 0;
    }
}


.footerNone {
    display: none;
}