.home {
    justify-content: center;


    &-logo {
        position: absolute;
        inset: 0% auto auto 0%;
        margin: 20px;
        width: 8vh;
        height: 5vh;
        // filter: drop-shadow(0px 0px 1px #ffffff);
    }

    &-titre {
        position: absolute;
        top: 26%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 0 10%;

        h1 {
            font-family: Ambidexter;
            font-size: 8vh;
            font-weight: 100;
            margin: 0;
            text-shadow: 0 0 15px #000;
        }

        &-infos {
            font-family: Aileron-Light;
            font-size: 1.4vh;
            display: flex;
            font-weight: 900;
            letter-spacing: 0.194em;
            justify-content: center;
            text-transform: uppercase;
            color: #ffffffc7;


        }

        &-sousInfos {
            padding: 10px;
            border-radius: 0.9375rem;
            background-color: rgba(26, 26, 26, 0.49);
            -webkit-backdrop-filter: blur(12px);
            backdrop-filter: blur(61px);
            border: rgb(255, 255, 255) 0.1px solid;
            color: rgb(255, 255, 255);
            margin: 12px;
        }

        &--integrateur {
            font-size: 2vh;
        }
    }

}