.quisuisje-texte {
    font-family: Aileron-Light;
    font-size: 2.7vh;
    position: absolute;
    top: auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0 10%;

    a {
        color: white;
        padding: 1vh;
        border-radius: 0.9375rem;
        border: rgb(255, 255, 255) 0.1px solid;
        line-height: 2;
    }
}