.main-erreur {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;

    h1 {
        font-size: 33vh;
        margin: 0;
    }

    p {
        font-size: 5vh;
    }

    a {
        color: #FF6060;
        font-size: 3vh;

    }
}

@media screen and (max-width: 768px) {

    .main-erreur {

        h1 {
            font-size: 28vw;
        }

        p {
            font-size: 4vw;
            margin: 40px;
            text-align: center;
        }

        a {
            font-size: 3vw;
        }
    }


}