#MenuProjets {
    display: flex;
    gap: 4vmin;
    position: absolute;
    left: 10%;
    top: 4%;
    transform: translate(0%, -50%);
    -webkit-user-select: none;
    user-select: none;
    z-index: 4;
    backdrop-filter: blur(26px);
    background-color: rgba(233, 232, 227, .7);
    border: 1px solid #D4D3C9;
    padding: 1vh;
    border-radius: 0.5rem;
    cursor: pointer;


}

#MenuProjets>.image {
    width: 40vmin;
    height: 4vmin;
    object-fit: cover;
    object-position: 100% center;
    border-radius: 0.5rem;
}