.sectionProjetsKasa {
    background-color: #FF6060 !important;
}

.sectionProjets {

    .couvertureKasa {
        height: 84vh !important;

    }

    .kasaIntro {
        display: block;
        overflow: hidden;
        height: 70vh;
        margin: 5vh 21vh;
        border-radius: 9.5vh;
        color: #FFF;
        text-align: center;
        padding: 3vh;
        border: 1px solid rgba(0, 0, 0, 0.4392156863);
        width: 100vh;
        font-family: Aileron-Regular;
        font-weight: 900;
        font-size: 2.4vh;
        box-shadow: 10px 5px 5px #0000002b;


        div {
            height: 50%;
            background: white;
            overflow: hidden;
            border-radius: 9.5vh 9.5vh 0 0;
            position: relative;
            display: flex;
            align-items: center;
            padding: 4vh;
            height: 40%;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.92);
                z-index: 1;
            }

            img {
                width: 100%;
                object-fit: cover;
                object-position: 100% top;
                top: 0vh;
                left: unset;
                z-index: 0;
                filter: grayscale(80%);
            }

            p {
                position: absolute;
                z-index: 2;
                color: #FF6060;
                width: 90%;
            }
        }

    }

    .messageLaura {
        width: unset;
        color: rgba(0, 0, 0, 0.75);
        font-family: Aileron-Regular;
        font-weight: 900;

        .messageLaura-1 {
            background-color: #f8f7f3 !important;
            padding: 6vh;
            width: 73vh;
            height: 26vh;
            border-right: 5px dashed #FF6060;
            border-radius: 5vh;
            font-size: 3vh;
            filter: drop-shadow(14px 16px 3px #00000045);

            img {
                position: absolute;
                top: 0vh;
                left: unset;
                z-index: 0;
                width: 20vh;
                height: 100%;
                object-fit: cover;
                object-position: 50%;
                cursor: zoom-in;
            }
        }

        .messageLaura-2 {
            display: flex;
            font-size: 3vh;
            border-radius: 5vh;

            p {
                padding: 5vh;
                background-color: #f8f7f3 !important;
                -webkit-backdrop-filter: blur(3px);
                backdrop-filter: blur(3px);
                border-radius: 5vh;
                font-size: 2.7vh;
                width: 70vh;
                filter: drop-shadow(14px 16px 3px #00000045);

                &:nth-child(2) {
                    border-left: 5px dashed #FF6060;
                    border-right: 5px dashed #FF6060;
                    margin-top: 1vh;
                    height: 29vh;
                    transform: rotate(-2deg);
                    margin-left: 0.3vh;
                }

                &:nth-child(3) {
                    border-left: 5px dashed #FF6060;
                    border-right: 5px dashed #FF6060;
                    margin-top: 1vh;
                    height: 29vh;
                    transform: rotate(2deg);
                    margin-left: 0.3vh;
                }

                &:nth-child(4) {
                    border-left: 5px dashed #FF6060;
                    margin-top: -1vh;
                    height: 29vh;
                    transform: rotate(20deg);
                    margin-left: 10.3vh;
                }
            }

        }



    }

    .messagePaul2 {
        font-family: Aileron-regular;

        .messagePaulBlocs {
            position: relative;
            height: 80%;
            margin: 0 10vh;
            width: 200vh;
            display: flex;
            align-items: center;

            p {
                padding: 2vh;
                background-color: #FF6060;
                border: 11px solid #FFF;
                backdrop-filter: blur(3px);
                border-radius: 2.5vh;
                z-index: 1;
                position: relative;
                font-weight: 800;
                font-size: 3.2vh;
                width: 240vh;
                margin: 5vh;
                color: #FFF;
            }

            img {
                height: 70%;
                width: 50%;
                filter: grayscale(80%);
                animation: morph 20s forwards infinite;
                border-radius: 35vh 31vh 27vh 92vh/87% 54% 88% 53%;
                cursor: zoom-in;
                object-fit: cover;
                object-position: 50%;
                cursor: zoom-in;
            }
        }

    }


    .VideoKasa {
        border-radius: 0 !important;
        background-color: unset !important;
        border: 0 !important;

        h2 {
            color: #FFF !important;
        }

        video {
            border-radius: 0vh !important;

        }
    }

    .articleSiteKasa {
        border: 2vh solid rgba(226, 225, 221, 0.8509803922) !important;

    }
}


@keyframes morph {
    0% {
        border-radius: 35vh 31vh 27vh 92vh/87% 54% 88% 53%;
    }

    30% {
        border-radius: 50% 265% 100% 40%/69% 85% 183% 72%;
    }

    50% {
        border-radius: 30% 100% 70% 50% / 50% 10% 30% 60%;
    }

    80% {
        border-radius: 40% 141% 158% 80%/41% 126% 85% 8%;
    }

    100% {
        border-radius: 100% 100% 100% 100% / 40% 100% 70% 40%;
    }
}