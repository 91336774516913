#navBlanc {
    background-color: rgba(233, 232, 227, .7);
    border: 1px solid #D4D3C9;

    a {
        color: rgba(0, 0, 0, 0.75);
    }
}

.menu-principale {
    position: fixed;
    inset: auto 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
    font-family: Aileron-Light;
    font-weight: bold;
    font-size: 2vh;
    z-index: 999;

    nav {
        border-radius: 2.5vh;
        background-color: rgba(18, 18, 18, 0.42);
        backdrop-filter: blur(3px);
        border: #ffffff14 0.1px solid;


        ul {

            .menu-principale-projetBtn {
                background-color: #fff;
                padding: 1.5vh;
                border-radius: 0.9375rem;
                animation: rainbow 10s infinite;

                a {
                    color: #000000;
                }
            }


            li {
                margin: 0.2vh 2vh;


                a {
                    color: rgba(255, 255, 255, 0.75);
                }
            }

        }


    }


}



/**
 * CSS animation - glowing black box
 */
@keyframes rainbow {

    from,
    to {
        box-shadow: 0px 0px 25px 0 rgb(255, 255, 255);
    }

    16% {

        box-shadow: 0 0px 15px 0 rgb(255, 255, 255);
        filter: blur(0px)
    }

    32% {
        box-shadow: 0 0px 15px 0 rgb(255, 255, 255);
        filter: blur(0px)
    }

    48% {
        box-shadow: 0 0 55px 0 rgba(255, 255, 255, 0.548);
        filter: blur(2px);
    }

    64% {
        box-shadow: -2px 0px 30px 0px rgb(0, 0, 0);
    }

    80% {
        box-shadow: 0px 0px 15px 0 rgb(68, 118, 165);
    }
}